export default {
  data () {
    return {
      tableLoading: false,
      form: {
        user_id: '',
        subject_id: '',
        ShowOnlyCanBeChecked: 'on',
        ShowTestUser: '',
        page: 1,
        limit: 10
      },
      info: {
        isEduAdmin: false,
        edu_user_id: '',
        userInAnyGroup: false,
      },
      suubjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'cz_checkTestAll_checkTest_admin',
      tableColumns: [
        { label: '试卷ID', isShow: true },
        { label: '科目名称', isShow: true },
        { label: '模块名称', isShow: true },
        { label: '用户ID', isShow: true },
        { label: '用户手机号', isShow: true },
        { label: '用户姓名', isShow: true },
        { label: '所在学校', isShow: true },
        { label: '试卷时间', isShow: true },
        { label: '是否批改', isShow: true },
        { label: '操作', isShow: true },
      ],
      userInfo: {}
    }
  }
}